<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('EditShiftinfo') }}</span>
				<span v-else>{{ l('CreateShiftinfo') }}</span>
			</div>
		</div>
		<a-config-provider :locale="zh_CN">
			<a-form :form="form">
				<a-row :gutter="8" class="box">
					<a-row>
						<a-col :span="4">
							<q-label required :label="l('Name')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-input
								:placeholder="l('Name')"
								v-model="entity.name"
								v-decorator="['Name', { rules: [{ required: true, message: l('CanNotNull') }] }]"
							/>
						</a-col>
						<a-col :span="4">
							<q-label required :label="l('Code')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-input
								:placeholder="l('Code')"
								v-model="entity.code"
								style="width: 100%"
								v-decorator="['Code', { rules: [{ required: true, message: l('CanNotNull') }] }]"
							/>
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="4">
							<q-label required :label="l('WorkType')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-select
								show-search
								:placeholder="l('WorkType')"
								option-filter-prop="children"
								style="width: 100%"
								aria-required="true"
								v-model="entity.workTypeCode"
							>
								<a-select-option v-for="item in WorkTypeList" :key="item.itemDetailCode">
									{{ item.itemDetailName }}
								</a-select-option>
							</a-select>
						</a-col>
						<a-col :span="4">
							<q-label required :label="l('IsNightShift')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-radio-group name="radioGroup" v-model="isNightShift">
								<a-radio :value="true">
									是
								</a-radio>
								<a-radio :value="false">
									否
								</a-radio>
							</a-radio-group>
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="4">
							<q-label required :label="l('WorkStartTime')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-time-picker
								:allowClear="false"
								v-model="startTime"
								format="HH:mm"
								:defaultValue="startTime"
								@change="getTime"
							/>
						</a-col>
						<a-col :span="4">
							<q-label required :label="l('TakeCardRange')"> </q-label>
						</a-col>
						<a-col :span="4">
							<a-time-picker :allowClear="false" v-model="entity.upCardStartTime" format="HH:mm" />
						</a-col>
						<a-col :span="4">
							<a-time-picker :allowClear="false" v-model="entity.upCardEndTime" format="HH:mm" />
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="4">
							<q-label required :label="l('WorkEndTime')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-time-picker :allowClear="false" v-model="endTime" format="HH:mm" @change="getTime" />
						</a-col>
						<a-col :span="4">
							<q-label required :label="l('TakeCardRange')"> </q-label>
						</a-col>
						<a-col :span="4">
							<a-time-picker :allowClear="false" v-model="entity.downCardStartTime" format="HH:mm" />
						</a-col>
						<a-col :span="4">
							<a-time-picker :allowClear="false" v-model="entity.downCardEndTime" format="HH:mm" />
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="4">
							<q-label :label="l('RestTime')"> </q-label>
						</a-col>
						<a-col :span="4">
							<a-time-picker
								:allowClear="false"
								v-model="restStartTime"
								format="HH:mm"
								@change="getTime"
							/>
						</a-col>

						<a-col :span="4">
							<a-time-picker :allowClear="false" v-model="restEndTime" format="HH:mm" @change="getTime" />
						</a-col>
						<a-col :span="4">
							<q-label :label="l('OverTimeRest')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-time-picker :allowClear="false" v-model="afterWorkOTStart" format="HH:mm" />
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="4">
							<q-label :label="l('WorkTimesSUM')"> </q-label>
						</a-col>
						<a-col :span="20">
							<a-row>
								合计工作时长{{ hourtime }}小时{{ mintime }}分钟，计为
								<a-input-number
									v-model="WorkDays"
									style="width: 80px"
									:defaultValue="WorkDays"
								></a-input-number>
								天出勤，休息
								{{ entity.restMinutes }}分钟
							</a-row>
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="4">
							<q-label :label="l('LateMinutes')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-input-number
								:placeholder="l('LateMinutes')"
								v-model="entity.lateMinutes"
								style="width: 100%"
							/>
						</a-col>

						<a-col :span="4">
							<q-label :label="l('LeaveEarlyMinutes')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-input-number
								:placeholder="l('LeaveEarlyMinutes')"
								v-model="entity.leaveEarlyMinutes"
								style="width: 100%"
							/>
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="4">
							<q-label :label="l('AbsenteeismMinutes')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-input-number
								:placeholder="l('AbsenteeismMinutes')"
								v-model="entity.absenteeismMinutes"
								style="width: 100%"
							/>
						</a-col>

						<a-col :span="4">
							<q-label :label="l('Allowance')"> </q-label>
						</a-col>
						<a-col :span="8">
							<a-input-number
								:placeholder="l('Allowance')"
								v-model="entity.allowance"
								style="width: 100%"
							/>
						</a-col>
					</a-row>
				</a-row>
			</a-form>
		</a-config-provider>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button @click="close" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { Dic } from '../../../../shared/utils';
import jss from '../../../../../libs/abp/abp';
import moment from 'moment';
import {
	ShiftInfoServiceProxy,
	ShiftInfoEditDto,
	CreateOrUpdateShiftInfoInput,
} from '../../../../shared/service-proxies/service-proxies';
import { data } from 'jquery';
import { count } from 'rxjs/operators';

export default {
	name: 'create-or-edit-shiftinfo',
	mixins: [ModalComponentBase],
	data() {
		return {
			spinning: false,
			id: null,
			zh_CN,
			WorkTypeList: [],
			itemDetailCode: '',
			radioGroup: undefined,
			form: this.$form.createForm(this, { name: 'coordinated' }),
			hourtime: 0,
			mintime: 0,
			entity: new ShiftInfoEditDto(),
			isNightShift: false,
			WorkDays: 1,
			startTime: undefined,
			endTime: undefined,
			restStartTime: undefined,
			restEndTime: undefined,
			afterWorkOTStart: undefined,
		};
	},
	created() {
		this.fullData();
		this._shiftInfoServiceProxy = new ShiftInfoServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.WorkTypeList = await Dic.getInstance().getDicAsync('Att_WorkType');
		this.entity.restMinutes = 0;
		if (this.id != null) {
			this.getData();
			this.getTime();
		}
	},
	watch: {
		startTime: function(vars) {
			//this.getTime();
		},
		endTime: function(vars) {
			this.getTime();
		},
		restStartTime: function(vars) {
			//this.getTime();
		},
		restEndTime: function(vars) {
			//this.getTime();
		},
	},
	methods: {
		getData() {
			this.spinning = true;
			this._shiftInfoServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.shiftInfo;
					this.startTime = moment(res.shiftInfo.startTime, 'HH:mm');
					this.endTime = moment(res.shiftInfo.endTime, 'HH:mm');
					this.entity.upCardStartTime = moment(res.shiftInfo.upCardStartTime, 'HH:mm');
					this.entity.upCardEndTime = moment(res.shiftInfo.upCardEndTime, 'HH:mm');
					this.entity.downCardStartTime = moment(res.shiftInfo.downCardStartTime, 'HH:mm');
					this.entity.downCardEndTime = moment(res.shiftInfo.downCardEndTime, 'HH:mm');
					this.restStartTime =
						res.shiftInfo.restStartTime != null ? moment(res.shiftInfo.restStartTime, 'HH:mm') : null;
					this.restEndTime =
						res.shiftInfo.restEndTime != null ? moment(res.shiftInfo.restEndTime, 'HH:mm') : null;
					this.afterWorkOTStart =
						res.shiftInfo.afterWorkOTStart != null ? moment(res.shiftInfo.afterWorkOTStart, 'HH:mm') : null;
					this.isNightShift = this.entity.isNightShift;
					this.WorkDays = this.entity.workDays;
				});
			this.getTime();
		},

		getRemainderTime(startime, endtime, mstime) {
			startime = moment(startime).format('YYYY-MM-DD HH:mm:00');
			endtime = moment(endtime).format('YYYY-MM-DD HH:mm:00');
			startime = new Date(startime);
			endtime = new Date(endtime);
			var runTime = parseInt((endtime - startime) / 1000);
			var year = Math.floor(runTime / 86400 / 365);
			runTime = runTime % (86400 * 365);
			var month = Math.floor(runTime / 86400 / 30);
			runTime = runTime % (86400 * 30);
			var day = Math.floor(runTime / 86400);
			runTime = runTime % 86400;
			var hour = Math.floor(runTime / 3600);
			runTime = runTime % 3600;
			var minute = Math.floor(runTime / 60);
			runTime = runTime % 60;
			var second = runTime;
			var mstime = { shour: hour, sminute: minute };
			//mstime.shour=hour
			//mstime.sminute=minute
			return mstime;
		},

		worktime() {
			//工作时间计算(分钟)
			if (this.startTime > this.endTime) {
				this.endTime = moment(this.endTime).add(1, 'days');
				var mstime = this.getRemainderTime(this.startTime, this.endTime);
				this.hourtime = mstime.shour;
				this.mintime = mstime.sminute;
				this.workTimes = this.hourtime * 60 + this.mintime;
				var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
				return alltimes;
			} else {
				var mstime = this.getRemainderTime(this.startTime, this.endTime);
				this.hourtime = mstime.shour;
				this.mintime = mstime.sminute;
				this.workTimes = this.hourtime * 60 + this.mintime;
				var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
				return alltimes;
			}
			return alltimes;
		},

		gethourtime() {
			//工作时间计算(小时)
			if (this.startTime > this.endTime) {
				this.endTime = moment(this.endTime).add(1, 'days');
				var mstime = this.getRemainderTime(this.startTime, this.endTime);
				this.hourtime = mstime.shour;
				this.mintime = mstime.sminute;
				var gethourtimes = this.hourtime * 60 + this.mintime;
				var allhourtimes = gethourtimes / 60;
				var Gethourtimes = parseInt(allhourtimes * 10) / 10;
				this.workTimes = Number(Gethourtimes);
				var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
				return alltimes;
			} else {
				var mstime = this.getRemainderTime(this.startTime, this.endTime);
				this.hourtime = mstime.shour;
				this.mintime = mstime.sminute;
				var gethourtimes = this.hourtime * 60 + this.mintime;
				var allhourtimes = gethourtimes / 60;
				var Gethourtimes = parseInt(allhourtimes * 10) / 10;
				this.workTimes = Number(Gethourtimes);
				var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
				return alltimes;
			}
			return alltimes;
		},

		/**
		 * 总时间计算方法
		 */
		getTime() {
			if (this.endTime != null && this.startTime != null) {
				if (this.restStartTime != null && this.restEndTime != null) {
					if (this.startTime > this.endTime) {
						this.endTime = moment(this.endTime).add(1, 'days');
					}
					var reststarthour = new Date(this.restStartTime).getHours();
					var restendhour = new Date(this.restEndTime).getHours();
					var nowday = new Date().getDate();
					var reststartday = new Date(this.restStartTime).getDate();
					var restendday = new Date(this.restEndTime).getDate();
					//初始化结束时间的日期天数
					if (restendday != nowday) {
						this.restEndTime = moment(this.restEndTime).subtract(1, 'days');
					}
					if (reststartday != nowday) {
						this.restStartTime = moment(this.restStartTime).subtract(1, 'days');
					}
					//当休息结束时间为0点时
					if (this.restStartTime > this.restEndTime && restendhour == 0) {
						this.restEndTime = moment(this.restEndTime).add(1, 'days');
					}
					//当休息开始时间为0点时
					if (this.restStartTime < this.restEndTime && reststarthour == 0 && restendhour != 0) {
						this.restStartTime = moment(this.restStartTime).add(1, 'days');
						this.restEndTime = moment(this.restEndTime).add(1, 'days');
					}
					if (reststarthour > restendhour && restendhour != 0) {
						this.restEndTime = moment(this.restEndTime).add(1, 'days');
						//时间包含休息时间
						if (this.startTime <= this.restStartTime && this.endTime >= this.restEndTime) {
							//this.restEndTime = moment(this.restEndTime).add(1, "days");
							var mstime = this.getRemainderTime(this.restStartTime, this.restEndTime);
							this.entity.restMinutes = mstime.shour * 60 + mstime.sminute;
							var worktime = this.worktime();
							var getmintime = (worktime.wtime - this.entity.restMinutes) / 60;
							this.hourtime = parseInt(getmintime);
							this.mintime = (worktime.wtime - this.entity.restMinutes) % 60;
							var Getmintime = getmintime.toFixed(1);
							this.workTimes = Number(Getmintime);
							var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
						} else if (
							//时间不包含休息时间
							(this.startTime <= this.restStartTime && this.endTime <= this.restStartTime) ||
							(this.startTime >= this.restEndTime && this.endTime >= this.restEndTime)
						) {
							//this.restEndTime = moment(this.restEndTime).add(1, "days");
							var mstime = this.getRemainderTime(this.restStartTime, this.restEndTime);
							this.entity.restMinutes = mstime.shour * 60 + mstime.sminute;
							var worktime = this.worktime();
							var getmintime = worktime.wtime / 60;
							this.hourtime = parseInt(getmintime);
							this.mintime = worktime.wtime % 60;
							var Getmintime = getmintime.toFixed(1);
							this.workTimes = Number(Getmintime);
							var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
						} else if (
							//结束时间在里面
							this.startTime < this.restStartTime &&
							this.endTime > this.restStartTime
						) {
							var manyTime = this.getRemainderTime(this.restStartTime, this.endTime);
							//this.restEndTime = moment(this.restEndTime).add(1, "days");
							var mstime = this.getRemainderTime(this.restStartTime, this.restEndTime);
							this.entity.restMinutes = mstime.shour * 60 + mstime.sminute;
							var worktime = this.worktime();
							var getmintime = (worktime.wtime - (manyTime.shour * 60 + manyTime.sminute)) / 60;
							this.hourtime = parseInt(getmintime);
							this.mintime = (worktime.wtime - (manyTime.shour * 60 + manyTime.sminute)) % 60;
							var Getmintime = getmintime.toFixed(1);
							this.workTimes = Number(Getmintime);
							var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
						} else if (
							//开始时间在里面
							this.startTime < this.restEndTime &&
							this.endTime > this.restEndTime
						) {
							var manyTime = this.getRemainderTime(this.startTime, this.restEndTime);
							//this.restEndTime = moment(this.restEndTime).add(1, "days");
							var mstime = this.getRemainderTime(this.restStartTime, this.restEndTime);
							this.entity.restMinutes = mstime.shour * 60 + mstime.sminute;
							var worktime = this.worktime();
							var getmintime = (worktime.wtime - (manyTime.shour * 60 + manyTime.sminute)) / 60;
							this.hourtime = parseInt(getmintime);
							this.mintime = (worktime.wtime - (manyTime.shour * 60 + manyTime.sminute)) % 60;
							var Getmintime = getmintime.toFixed(1);
							this.workTimes = Number(Getmintime);
							var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
						}
						return alltimes;
					} else {
						//时间包含休息时间
						if (this.startTime <= this.restStartTime && this.endTime >= this.restEndTime) {
							var mstime = this.getRemainderTime(this.restStartTime, this.restEndTime);
							this.entity.restMinutes = mstime.shour * 60 + mstime.sminute;
							var worktime = this.worktime();
							var getmintime = (worktime.wtime - this.entity.restMinutes) / 60;
							this.hourtime = parseInt(getmintime);
							this.mintime = (worktime.wtime - this.entity.restMinutes) % 60;
							var Getmintime = getmintime.toFixed(1);
							this.workTimes = Number(Getmintime);
							var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
						} else if (
							//时间不包含休息时间
							(this.startTime <= this.restStartTime && this.endTime <= this.restStartTime) ||
							(this.startTime >= this.restEndTime && this.endTime >= this.restEndTime)
						) {
							var mstime = this.getRemainderTime(this.restStartTime, this.restEndTime);
							this.entity.restMinutes = mstime.shour * 60 + mstime.sminute;
							var worktime = this.worktime();
							var getmintime = worktime.wtime / 60;
							this.hourtime = parseInt(getmintime);
							this.mintime = worktime.wtime % 60;
							var Getmintime = getmintime.toFixed(1);
							this.workTimes = Number(Getmintime);
							var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
						} else if (
							//结束时间在里面
							this.startTime < this.restStartTime &&
							this.endTime > this.restStartTime
						) {
							var manyTime = this.getRemainderTime(this.restStartTime, this.endTime);
							var mstime = this.getRemainderTime(this.restStartTime, this.restEndTime);
							this.entity.restMinutes = mstime.shour * 60 + mstime.sminute;
							var worktime = this.worktime();
							var getmintime = (worktime.wtime - (manyTime.shour * 60 + manyTime.sminute)) / 60;
							this.hourtime = parseInt(getmintime);
							this.mintime = (worktime.wtime - (manyTime.shour * 60 + manyTime.sminute)) % 60;
							var Getmintime = getmintime.toFixed(1);
							this.workTimes = Number(Getmintime);
							var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
						} else if (
							//开始时间在里面
							this.startTime < this.restEndTime &&
							this.endTime > this.restEndTime
						) {
							var manyTime = this.getRemainderTime(this.startTime, this.restEndTime);
							var mstime = this.getRemainderTime(this.restStartTime, this.restEndTime);
							this.entity.restMinutes = mstime.shour * 60 + mstime.sminute;
							var worktime = this.worktime();
							var getmintime = (worktime.wtime - (manyTime.shour * 60 + manyTime.sminute)) / 60;
							this.hourtime = parseInt(getmintime);
							this.mintime = (worktime.wtime - (manyTime.shour * 60 + manyTime.sminute)) % 60;
							var Getmintime = getmintime.toFixed(1);
							this.workTimes = Number(Getmintime);
							var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
						}
						// console.log(alltimes)
						return alltimes;
					}
					return alltimes;
				} else {
					this.entity.restMinutes = 0;
					var worktime = this.gethourtime();
					this.workTimes = worktime.wtime;
					this.hourtime = worktime.hour;
					this.mintime = worktime.minute;
					var alltimes = { hour: this.hourtime, minute: this.mintime, wtime: this.workTimes };
					return alltimes;
				}
				return alltimes;
			}
		},

		save() {
			if (!this.entity.name) {
				return abp.message.warn('请输入班次名称');
			}
			if (!this.entity.code) {
				return abp.message.warn('请输入编码');
			}
			if (!this.startTime) {
				return abp.message.warn('请输入上班时间');
			}
			if (!this.endTime) {
				return abp.message.warn('请输入下班时间');
			}
			if (!this.entity.upCardStartTime) {
				return abp.message.warn('请输入上班取卡时间');
			}
			if (!this.entity.upCardEndTime) {
				return abp.message.warn('请输入上班结束取卡时间');
			}
			if (!this.entity.downCardStartTime) {
				return abp.message.warn('请输入下班取卡时间');
			}
			if (!this.entity.downCardEndTime) {
				return abp.message.warn('请输入下班结束取卡时间');
			}
			this._shiftInfoServiceProxy
				.getRepeat(this.id, this.entity.code)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					if (res.length === 0) {
						var gettime = this.getTime();
						const input = new CreateOrUpdateShiftInfoInput();
						this.entity.name = this.entity.name;
						this.entity.code = this.entity.code;
						this.entity.workTypeCode = this.entity.workTypeCode;
						this.entity.isNightShift = this.isNightShift;
						this.entity.startTime = moment(this.startTime).format('HH:mm');
						this.entity.endTime = moment(this.endTime).format('HH:mm');
						this.entity.upCardStartTime = moment(this.entity.upCardStartTime).format('HH:mm');
						this.entity.upCardEndTime = moment(this.entity.upCardEndTime).format('HH:mm');
						this.entity.downCardStartTime = moment(this.entity.downCardStartTime).format('HH:mm');
						this.entity.downCardEndTime = moment(this.entity.downCardEndTime).format('HH:mm');
						this.entity.restStartTime =
							this.restStartTime != null ? moment(this.restStartTime).format('HH:mm') : null;
						this.entity.restEndTime =
							this.restEndTime != null ? moment(this.restEndTime).format('HH:mm') : null;
						this.entity.workHours = gettime.wtime;
						this.entity.workDays = this.WorkDays;
						this.entity.restMinutes = this.entity.restMinutes;
						this.entity.allowance = this.entity.allowance;
						this.entity.lateMinutes = this.entity.lateMinutes;
						this.entity.leaveEarlyMinutes = this.entity.leaveEarlyMinutes;
						this.entity.absenteeismMinutes = this.entity.absenteeismMinutes;
						this.entity.afterWorkOTStart =
							this.afterWorkOTStart != null ? moment(this.afterWorkOTStart).format('HH:mm') : null;
						input.shiftInfo = ShiftInfoEditDto.fromJS(this.entity);
						this.spinning = true;
						this._shiftInfoServiceProxy
							.createOrUpdate(input)
							.finally(() => {
								//this.spinning = false;
							})
							.then((res) => {
								this.$notification['success']({
									message: this.l('SavedSuccessfully'),
								});
								this.success(true);
							});
					} else {
						this.$notification['error']({
							message: 'code信息重复,保存失败',
						});
					}
				});
		},
	},
};
</script>

<style scoped></style>
