<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8">
				<a-col :span="24" style="display: flex; align-items: center; justify-content: space-between;">
					<div>
						<a-button :type="'primary'" @click="createOrEdit()" v-if="isGranted('shiftinfo_add')">
							<a-icon type="plus" />
							<span>{{ l('Create') }}</span>
						</a-button>
						<a-button :type="'primary'" @click="EditPost()" v-if="isGranted('shiftinfo_edit')">
							<a-icon type="edit" />
							<span>{{ l('Edit') }}</span>
						</a-button>
						<a-button :type="'danger'" @click="BatchDelete()" v-if="isGranted('shiftinfo_delete')">
							<a-icon type="delete" />
							<span>{{ l('Delete') }}</span>
						</a-button>
					</div>
					<a-input-search
						style="width: 200px"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
						v-decorator="['filterText']"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<p class="subscriptionEndUtc" slot="subscriptionEndUtc" slot-scope="record">
					{{ record }}
				</p>
				<a class="isActive" slot="isActive" slot-scope="record">
					<a-icon v-if="record" type="check" />
					<a-icon v-if="!record" type="close" />
				</a>
				<p class="creationTime" slot="creationTime" slot-scope="record">
					{{ record | moment('YYYY-MM-DD') }}
				</p>
				<span slot-scope="text, record" slot="startTime"> {{ record.startTime }}~{{ record.endTime }} </span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import { ShiftInfoServiceProxy } from '../../../shared/service-proxies';
import { ModalHelper } from '@/shared/helpers';
import CreateOrEditShiftinfo from './create-or-edit-shiftinfo/create-or-edit-shiftinfo';
import { Dic } from '../../../shared/utils';

export default {
	name: 'shiftinfo',
	mixins: [AppComponentBase],
	components: { CreateOrEditShiftinfo },
	data() {
		return {
			workid: undefined,

			WorkTypeList: [],
			columns: [
				{
					title: '',
					dataIndex: 'checkbox',
					sorter: false,
					fixed: 'left',
					width: 50,
					align: 'center',
					scopedSlots: { customRender: 'checkbox' },
				},
				{
					title: this.l('Code'),
					dataIndex: 'code',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'code' },
				},
				{
					title: this.l('Name'),
					dataIndex: 'name',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'name' },
				},
				{
					title: this.l('AttendanceTime'),
					dataIndex: 'startTime',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'startTime' },
					// customRender: (text, row, index) => {
					//     var message = this.getTime(text);
					//     return {
					//         children: message,
					//         attrs: {},
					//     };
					// },
				},
				{
					title: this.l('WorkType'),
					dataIndex: 'workTypeCode',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'workTypeCode' },
					customRender: (text, row, index) => {
						var message = this.getName(text);
						return {
							children: message,
							attrs: {},
						};
					},
				},
			],
			tableData: [],
		};
	},
	async mounted() {
		this._shiftInfoServiceProxy = new ShiftInfoServiceProxy(this.$apiUrl, this.$api);
		this.getData();
		this.WorkTypeList = await Dic.getInstance().getDicAsync('Att_WorkType');
	},
	methods: {
		getData() {
			this.loading = true;
			this.selectedRowObj = {};
			this._shiftInfoServiceProxy
				.getPaged(this.filterText, this.request.sorting, this.request.maxResultCount, this.request.skipCount)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
				});
		},
		/**
		 * 转换工作日类型
		 */
		getName(text) {
			let worktypeName = '';
			this.WorkTypeList.forEach((item) => {
				if (text == item.itemDetailCode) {
					worktypeName = item.itemDetailName;
				}
			});
			return worktypeName;
		},
		createOrEdit() {
			ModalHelper.create(CreateOrEditShiftinfo, { id: undefined }, { width: '900px' }).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},

		/**
		 * 编辑信息
		 */
		EditPost() {
			if (this.selectedRowKeys.length <= 0 || this.selectedRowKeys.length > 1) {
				return abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
			}
			ModalHelper.create(CreateOrEditShiftinfo, { id: this.selectedRowKeys[0] }, { width: '900px' }).subscribe(
				(res) => {
					if (res) {
						this.getData();
					}
				}
			);
		},

		/**
		 * 批量删除
		 */
		BatchDelete() {
			const selectCount = this.selectedRowKeys.length;
			if (selectCount <= 0) {
				abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
				return;
			}
			this.$confirm({
				title: this.l('ConfirmInformation'),
				content: this.l('ConfirmDeleteXItemsWarningMessage', selectCount),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					const ids = _.map(this.selectedRowKeys);
					this.loading = true;
					this._shiftInfoServiceProxy
						.batchDelete(ids)
						.finally(() => {
							this.loading = false;
						})
						.then(() => {
							this.pageNumber = 1;
							this.request.skipCount = 0;
							this.getData();
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
						});
				},
			});
		},
	},
};
</script>

<style scoped></style>
